import React from "react";
import { motion } from "framer-motion";
import { Button } from "../button";

export default function HeroSection() {
  return (
    <div className="w-full">
      <div className="absolute inset-0 h-[110vh] w-full z0">
        <img
          src="landing/bg.webp"
          alt=""
          className="w-full h-full object-cover"
        />
      </div>
      <div className="absolute inset-0 h-[110vh] bg-gradient-to-b from-transparent via-transparent to-white w-full z-0"></div>
      <div className="text-center">
        <h1 className="font-title font-semibold relative z-10 m-auto text-slate-800 max-w-4xl sm:tracking-tight text-3xl sm:text-6xl lg:text-[80px]">
          Your environmental policy copilot
        </h1>
        <p className="relative z-10 mt-10 text-slate-600 max-w-prose mx-auto text-xl text-gray-500">
          Utilize the power of AI to track policy makers opinions and
          rapidly-evolving laws. Summarise laws, clarify obligations and define
          compliance.
        </p>
        <Button
          className="relative z-10 mt-10"
          href="https://copilot-dev.policycopilot.org/"
        >
          Get Started
        </Button>
      </div>
      <div className="relative w-full mt-12">
        <motion.img
          src="landing/heroui.webp"
          alt=""
          className="w-full"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          ease-out
        />
        <div className="absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-b from-transparent to-white"></div>
      </div>
    </div>
  );
}
