import { useState, useRef, useEffect } from "react";
import ImpactSVG from "../impact-svg";
import { Button } from "../button";

export default function ImpactSection() {
  const container = useRef(null);
  // const [scrollY, setScrollY] = useState(0);
  const [containerScroll, setContainerScroll] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollYProgress = window.scrollY;
      // setScrollY(scrollYProgress);

      if (container.current) {
        const containerTop =
          container.current.getBoundingClientRect().top + window.scrollY;
        const containerBottom = containerTop + container.current.offsetHeight;
        const viewportHeight = window.innerHeight;

        if (scrollYProgress < containerTop - viewportHeight) {
          setContainerScroll(0);
        } else if (scrollYProgress >= containerBottom) {
          setContainerScroll(100);
        } else {
          const scrollInContainer =
            scrollYProgress - (containerTop - viewportHeight);
          const containerHeightWithViewport =
            container.current.offsetHeight + viewportHeight;
          const scrollPercent =
            (scrollInContainer / containerHeightWithViewport) * 100;
          setContainerScroll(scrollPercent);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="w-full mt-48">
      <h1 className="font-title font-medium relative z-10 m-auto text-center text-slate-800 max-w-2xl sm:tracking-tight text-3xl sm:text-6xl lg:text-[80px]">
        Turn-by-turn policy navigation
      </h1>
      <div className="absolute w-screen h-[calc(100vw*9/20)] left-0">
        <div
          className="relative w-full h-[calc(100vw*9/20)] flex flex-col items-center"
          ref={container}
        >
          <ImpactSVG
            className="absolute w-screen h-auto left-0"
            scrollProgress={containerScroll}
          />
          <div className="absolute max-w-64 text-center top-[calc(32vw*9/20)] mr-[54vw]">
            <h3 className="font-title relative z-10 text-xl text-slate-800 font-medium">
              Save Time
            </h3>
            <p className="mt-2 z-10 relative text-sm text-slate-500">
              By automating the process of tracking legislative activity,
              analyzing bill implications, and predicting lawmaker stances, our
              tool helps users save hundreds of hours compared to manual
              research methods.
            </p>
          </div>
          <div className="absolute max-w-64 text-center top-[calc(61vw*9/20)]">
            <h3 className="font-title relative z-10 text-xl text-slate-800 font-medium">
              Respond Rapidly
            </h3>
            <p className="mt-2 z-10 relative text-sm text-slate-500">
              Real-time alerts on new developments and customized insights
              enable users to respond rapidly to legislative changes, giving
              them a competitive edge in policy advocacy.
            </p>
          </div>
          <div className="absolute max-w-64 text-center top-[calc(32vw*9/20)] ml-[54vw]">
            <h3 className="font-title relative z-10 text-xl text-slate-800 font-medium">
              Achieve Better Outcomes
            </h3>
            <p className="mt-2 z-10 relative text-sm text-slate-500">
              The tool's advanced analytics and predictive capabilities give
              users a deeper understanding of the policy landscape, allowing
              them to make more informed decisions and develop more effective
              advocacy strategies.
            </p>
          </div>
          <Button
            className="z-10 absolute z-10 bottom-0"
            href="https://copilot-dev.policycopilot.org/"
          >
            Get Started
          </Button>
        </div>
      </div>
      <div className="h-[calc(100vw*9/20)]"></div>
    </div>
  );
}
