import React from "react";
import NavBar from "../components/nav-bar";
import Footer from "../components/footer";
import DataSection from "../components/landing-sections/data-section";
import ImpactSection from "../components/landing-sections/impact-section";
import LegislationSection from "../components/landing-sections/legislation-section";
import FeatureSection from "../components/landing-sections/feature-section";
import HeroSection from "../components/landing-sections/hero-section";

export default function LandingPage() {
  return (
    <main className="flex min-h-screen w-full justify-center">
      <div className="container space-y-8 px-4 md:px-6">
        <NavBar />
        <div className="py-[7rem] md:py-[7rem] lg:py-[7rem]">
          <HeroSection />
          <FeatureSection />
          <LegislationSection />
          <ImpactSection />
          <DataSection />
        </div>
        <Footer />
      </div>
    </main>
  );
}
