import React from "react";

export default function Footer() {
  return (
    <div className="absolute left-0 w-screen bg-[#0B1C46] flex justify-center items-center">
      <div className="container flex text-white items-start py-20 gap-16">
        <img
          src="general/Pentatonic-logo-white.svg"
          alt=""
          className="fill-current max-w-32 text-white fill-white mt-1"
        />
        <div className="flex flex-col gap-3 text-sm">
          <h4 className="font-semibold mb-3">Company</h4>
          <div className="cursor-pointer">Contact Us</div>
          <div className="cursor-pointer">Careers</div>
          <div className="cursor-pointer">Privacy Policy</div>
          <div className="cursor-pointer">Terms & Conditions</div>
        </div>
        <div className="flex flex-col gap-3 text-sm">
          <h4 className="font-semibold mb-3">Addresses</h4>
          <div className="flex gap-16">
            <div>
              <div className="leading-relaxed">Pentatonic GMBH</div>
              <div className="leading-relaxed">Greifswalder Straße 51</div>
              <div className="leading-relaxed">10405 Berlin</div>
              <div className="leading-relaxed">Germany</div>
              <div className="mt-3">VAT: DE815781616</div>
            </div>
            <div>
              <div className="leading-relaxed">Pentatonic Ltd</div>
              <div className="leading-relaxed">27 Downham Road Unit 3</div>
              <div className="leading-relaxed">London N1 5AA</div>
              <div className="leading-relaxed">UK</div>
              <div className="mt-3">VAT: GB307379003</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
