import { useRef, useEffect, useState } from "react";

function ImpactSVG({ className, scrollProgress }) {
  const pathRef = useRef();
  const [pathLength, setPathLength] = useState(0);

  useEffect(() => {
    const path = pathRef.current;
    if (path) {
      const length = path.getTotalLength();
      setPathLength(length);
      path.style.strokeDasharray = `${length} ${length}`;
      path.style.strokeDashoffset = length;
    }
  }, []);

  useEffect(() => {
    if (pathLength > 0) {
      const drawLength = pathLength * (scrollProgress / 75);
      pathRef.current.style.strokeDashoffset = pathLength - drawLength;
    }
  }, [scrollProgress, pathLength]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2000"
      height="900"
      fill="none"
      viewBox="0 0 2000 900"
      className={className}
    >
      <path fill="#E2EAFF" d="M0 218H2000V238H0z"></path>
      <path
        fill="#E2EAFF"
        d="M1767.84 639.196H2000.001V659.297H1767.84z"
      ></path>
      <path
        fill="#E2EAFF"
        d="M662.312 474.372H1337.6889999999999V494.473H662.312z"
      ></path>
      <path fill="#E2EAFF" d="M662 218H682V900H662z"></path>
      <path fill="#E2EAFF" d="M146 5H166V900H146z"></path>
      <path fill="#E2EAFF" d="M567.839 5.025H587.94V238.191H567.839z"></path>
      <path fill="#E2EAFF" d="M1748 11H1768V898H1748z"></path>
      <path
        fill="#F3F6FF"
        fillRule="evenodd"
        d="M1866.33 3.015H2000v48.241h-70.42v60.302h-63.62V50.251h.37V3.015zm88.44 66.332H2000V191.96h-45.23V69.347zm-25.19 60.301h-63.62v62.312h63.62v-62.312zM1844.22 3.015h-52.26V191.96h52.26V3.015zM1844 683h-52v215h52V683zm18.31.417H2000v48.241h-73.37v60.302h-64.32V683.417zM1944 750h56v148h-56V750zm-18 60h-64v88h64v-88zM1791.96 490.452v-228.14h48.24v181.909h60.3v46.231H1791.96zm66.33-64.321V262.312H2000v163.819h-141.71zm60.3 18.09v46.231H2000v-46.231h-81.41zm-126.63 64.322v106.532H2000V508.543h-208.04zM1468.34 538.693h-106.53v188.945h106.53V538.693zm18.09 0h237.19v48.242h-190.96v60.301h-46.23V538.693zm64.32 66.332h172.87v122.613h-172.87V605.025zm-18.09 60.302h-46.23v62.311h46.23v-62.311zM1551 900h-189v-14h142.52v-59.87h46.23V886h.25v14zm-64.57-31.658h-124.62V745.728h124.62v122.614zm18.09-60.302h46.23v-62.312h-46.23v62.312zM1569 898h155V746h-155v152z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#E2EAFF"
        d="M1318 474h8c6.63 0 12 5.373 12 12v414h-20V474z"
      ></path>
      <path fill="#E2EAFF" d="M989.95 3.015H1010.051V481.407H989.95z"></path>
      <path
        fill="#E2EAFF"
        d="M1530.65 0H1550.7510000000002V218.09H1530.65z"
      ></path>
      <path
        fill="#E2EAFF"
        d="M1317.59 218.09H1337.6909999999998V494.472H1317.59z"
      ></path>
      <path
        fill="#F3F6FF"
        fillRule="evenodd"
        d="M1186.93 262.312h-96.48v83.417h96.48v-83.417zm106.54 0h-88.44v186.934h88.44V262.312zm-203.02 101.507h96.48v85.427h-96.48v-85.427zm-18.09-101.507h-38.19v186.934h38.19V262.312z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#F3F6FF"
        d="M1206.03 518.593H1293.4669999999999V564.824H1206.03z"
      ></path>
      <path fill="#F3F6FF" d="M707 649H797V900H707z"></path>
      <path
        fill="#F3F6FF"
        d="M1206.03 582.915H1293.4669999999999V720.603H1206.03z"
      ></path>
      <path fill="#F3F6FF" d="M706.532 518.593H796.984V631.156H706.532z"></path>
      <path
        fill="#F3F6FF"
        fillRule="evenodd"
        d="M784.925 262.312h-78.392v187.939h78.392V262.312zm18.091 0H965.83v34.17H837.186v75.377h-34.17V262.312zm52.261 52.261H965.83v135.678H855.277V314.573zm-18.091 75.377h-34.17v60.301h34.17V389.95zM253.266 261.307h-63.317v170.854h63.317V261.307zm-63.317 188.944h62.312v252.262h-62.312V450.251zm448.241 87.437H270.351v90.453h46.231V591.96H638.19v-54.272zm0 72.362H334.673v93.468H638.19V610.05zm-366.834 36.181h46.231v56.282h-46.231v-56.282zM918.593 193.97h46.231V67.337h-46.231V193.97zm-212.06 0h193.97v-48.241h-193.97v48.241zm0-66.332h190.954V5.025H706.533v122.613zm258.291-78.392h-46.231V3.016h46.231v46.23z"
        clipRule="evenodd"
      ></path>
      <path fill="#F3F6FF" d="M612.061 3.015H688.443V193.97H612.061z"></path>
      <path
        fill="#F3F6FF"
        fillRule="evenodd"
        d="M436.276 193.97h107.443V5.025H436.276V193.97zm-18.245 0H187.94v-48.241h183.464V85.427h46.627V193.97zm-64.872-66.332H187.94V5.025h165.219v122.613zm18.245-60.301h46.627V5.025h-46.627v62.312z"
        clipRule="evenodd"
      ></path>
      <path fill="#F3F6FF" d="M122.613 193.97H0V5.025h122.613V193.97z"></path>
      <path
        fill="#F3F6FF"
        fillRule="evenodd"
        d="M1141.71 3.015h-106.53V191.96h106.53V3.015zm18.09 0h228.14v48.241h-181.91v60.302h-46.23V3.015zm64.32 66.332h163.82V191.96h-163.82V69.347zm-18.09 60.301h-46.23v62.312h46.23v-62.312zM0 443.216v46.231h42.211v-46.231H0zm0-18.09V261.307h122.613v163.819H0zm60.301 64.321v-46.231h62.312v46.231H60.301z"
        clipRule="evenodd"
      ></path>
      <rect
        width="92.462"
        height="92.462"
        x="1494.47"
        y="181.91"
        fill="#2256DB"
        opacity="0.05"
        rx="30"
      ></rect>
      <rect
        width="82.412"
        height="82.412"
        x="1499.5"
        y="186.935"
        fill="#2256DB"
        opacity="0.1"
        rx="26"
      ></rect>
      <path
        fill="#F3F6FF"
        fillRule="evenodd"
        d="M0 804v96h126v-96H0zm0-18.07v-46.232h45.226v46.232H0zm3.015-228.141v163.819h122.613V557.789H3.015zm60.302 181.909v46.232h62.311v-46.232H63.317zM1014 900H815v-17h199v17zm2.08-34.674H815.076V742.713h201.004v122.613zm18.09-60.301h46.23v-62.312h-46.23v62.312zm46.23 79.397h-46.23v-61.307h46.23v61.307zm18.09 14.07h194.98V742.713h-194.98v155.779z"
        clipRule="evenodd"
      ></path>
      <path fill="#E2EAFF" d="M165.829 726.633H662.311V746.734H165.829z"></path>
      <path
        fill="#F3F6FF"
        fillRule="evenodd"
        d="M190 900h175V771H190v129zm192.914-66.834h46.231v-62.312h-46.231v62.312zM429 900h-46v-49h46v49zm18 0h191V771H447v129zM1574.87 193.97h148.75v-61.307h-148.75v61.307zm-168.84-2.01h100.5v-48.241h-100.5v48.241zm0-66.332h100.5V3.015h-100.5v122.613zm317.59-11.055h-149.75V52.261h149.75v62.312z"
        clipRule="evenodd"
      ></path>
      <path
        ref={pathRef}
        stroke="#2357DB"
        strokeWidth="8"
        d="M155.779 6.03015V219.638C155.779 224.056 159.361 227.638 163.779 227.638H663.357C667.775 227.638 671.357 231.22 671.357 235.638V477.462C671.357 481.895 674.962 485.483 679.395 485.462L1320.18 482.45C1324.58 482.429 1328.14 478.853 1328.14 474.45V235.638C1328.14 231.22 1331.72 227.638 1336.14 227.638H1864.32"
      ></path>
      <path
        fill="url(#paint0_linear_53_753)"
        d="M0 3.015H2000V286.43199999999996H0z"
      ></path>
      <path fill="url(#paint1_linear_53_753)" d="M0 549H2000V899.754H0z"></path>
      <g filter="url(#filter0_ddd_53_753)">
        <rect
          width="74"
          height="74"
          x="1503.7"
          y="191.141"
          fill="#fff"
          rx="24"
        ></rect>
        <rect
          width="74"
          height="74"
          x="1503.7"
          y="191.141"
          fill="url(#paint2_linear_53_753)"
          fillOpacity="0.4"
          rx="24"
        ></rect>
        <rect
          width="73"
          height="73"
          x="1504.2"
          y="191.641"
          stroke="url(#paint3_linear_53_753)"
          rx="23.5"
        ></rect>
        <g filter="url(#filter1_d_53_753)">
          <g filter="url(#filter2_di_53_753)">
            <path
              fill="url(#paint4_linear_53_753)"
              d="M1539.7 233.091l4.15 2.518c.31.199.61.182.92-.05.3-.232.41-.534.33-.907l-1.1-4.661 3.66-3.195c.3-.257.38-.558.26-.901-.13-.344-.38-.537-.76-.578l-4.81-.376-1.85-4.435c-.15-.348-.41-.522-.8-.522-.38 0-.64.174-.79.522l-1.86 4.435-4.81.376c-.37.041-.62.234-.75.578-.13.343-.04.644.25.901l3.66 3.195-1.09 4.661c-.08.369.03.671.33.904.3.234.61.252.92.053l4.14-2.518zm-6.74 11.349h-6.15c-.94 0-1.75-.332-2.41-.995-.66-.663-1-1.467-1-2.412v-6.124l-4.36-4.381c-.34-.34-.58-.715-.74-1.125-.15-.41-.23-.827-.23-1.253 0-.425.08-.846.23-1.262.16-.416.4-.794.74-1.134l4.36-4.382v-6.124c0-.948.34-1.755 1-2.42.66-.666 1.47-.998 2.41-.998h6.12l4.39-4.349c.34-.34.72-.586 1.15-.74.43-.153.86-.23 1.29-.23.43 0 .85.089 1.26.265.41.177.78.428 1.12.755l4.31 4.299h6.15c.94 0 1.75.332 2.42.998.66.665.99 1.472.99 2.42v6.124l4.35 4.382c.34.34.59.714.74 1.122.16.409.23.827.23 1.256 0 .428-.07.85-.23 1.264-.15.415-.4.792-.74 1.132l-4.35 4.381v6.124c0 .945-.33 1.749-.99 2.412-.67.663-1.48.995-2.42.995h-6.15l-4.31 4.261c-.34.318-.71.56-1.12.723-.41.164-.83.246-1.26.246-.42 0-.84-.082-1.26-.246a3.595 3.595 0 01-1.13-.723l-4.41-4.261zm1.37-3.407l5.42 5.219 5.28-5.219h7.57v-7.574l5.33-5.318-5.33-5.331v-7.562h-7.57l-5.28-5.33-5.37 5.33h-7.57v7.562l-5.32 5.331 5.32 5.318v7.574h7.52z"
            ></path>
          </g>
        </g>
      </g>
      <path fill="#E2EAFF" d="M0 513.568H145.729V533.669H0z"></path>
      <rect
        width="92.462"
        height="92.462"
        x="953.769"
        y="438.191"
        fill="#2256DB"
        opacity="0.05"
        rx="30"
      ></rect>
      <rect
        width="82.412"
        height="82.412"
        x="958.794"
        y="443.216"
        fill="#2256DB"
        opacity="0.1"
        rx="26"
      ></rect>
      <rect
        width="92.462"
        height="92.462"
        x="412.061"
        y="180.905"
        fill="#2256DB"
        opacity="0.05"
        rx="30"
      ></rect>
      <rect
        width="82.412"
        height="82.412"
        x="417.085"
        y="185.93"
        fill="#2256DB"
        opacity="0.1"
        rx="26"
      ></rect>
      <g filter="url(#filter3_ddd_53_753)">
        <rect
          width="74"
          height="74"
          x="963"
          y="447.422"
          fill="#fff"
          rx="24"
        ></rect>
        <rect
          width="74"
          height="74"
          x="963"
          y="447.422"
          fill="url(#paint5_linear_53_753)"
          fillOpacity="0.4"
          rx="24"
        ></rect>
        <rect
          width="73"
          height="73"
          x="963.5"
          y="447.922"
          stroke="url(#paint6_linear_53_753)"
          rx="23.5"
        ></rect>
        <g filter="url(#filter4_di_53_753)">
          <path
            fill="#E8EAED"
            d="M998.331 488.699l-13.139-1.504c-.62-.067-1.028-.402-1.221-1.005-.194-.604-.057-1.117.41-1.537l21.139-19.008c.1-.074.22-.138.33-.192.12-.054.3-.081.53-.081.39 0 .69.167.9.5.22.333.22.665 0 .996l-7.669 13.297 13.179 1.503c.62.067 1.03.399 1.23.997.2.597.07 1.106-.4 1.527l-21.136 19.026a1.266 1.266 0 01-.328.183c-.125.047-.305.071-.541.071a.976.976 0 01-.881-.49c-.202-.327-.202-.663 0-1.006l7.597-13.277z"
          ></path>
          <path
            fill="url(#paint7_linear_53_753)"
            d="M998.331 488.699l-13.139-1.504c-.62-.067-1.028-.402-1.221-1.005-.194-.604-.057-1.117.41-1.537l21.139-19.008c.1-.074.22-.138.33-.192.12-.054.3-.081.53-.081.39 0 .69.167.9.5.22.333.22.665 0 .996l-7.669 13.297 13.179 1.503c.62.067 1.03.399 1.23.997.2.597.07 1.106-.4 1.527l-21.136 19.026a1.266 1.266 0 01-.328.183c-.125.047-.305.071-.541.071a.976.976 0 01-.881-.49c-.202-.327-.202-.663 0-1.006l7.597-13.277z"
          ></path>
        </g>
      </g>
      <g filter="url(#filter5_ddd_53_753)">
        <rect
          width="74"
          height="74"
          x="421.291"
          y="190.136"
          fill="#fff"
          rx="24"
        ></rect>
        <rect
          width="74"
          height="74"
          x="421.291"
          y="190.136"
          fill="url(#paint8_linear_53_753)"
          fillOpacity="0.4"
          rx="24"
        ></rect>
        <rect
          width="73"
          height="73"
          x="421.791"
          y="190.636"
          stroke="url(#paint9_linear_53_753)"
          rx="23.5"
        ></rect>
        <path
          fill="url(#paint10_linear_53_753)"
          d="M452.649 208.136c-.468 0-.858-.159-1.168-.476a1.617 1.617 0 01-.465-1.177c0-.467.155-.854.465-1.161.31-.308.7-.461 1.168-.461h9.284c.469 0 .858.155 1.168.467.31.312.465.703.465 1.173 0 .467-.155.857-.465 1.168-.31.311-.699.467-1.168.467h-9.284zm4.649 22.601c.464 0 .851-.157 1.16-.469.31-.313.465-.701.465-1.163v-8.261c0-.462-.157-.849-.471-1.162a1.587 1.587 0 00-1.167-.469c-.464 0-.851.156-1.161.469-.309.313-.464.7-.464 1.162v8.261c0 .462.157.85.471 1.163.313.312.702.469 1.167.469zm-.01 16.899c-2.516 0-4.884-.483-7.102-1.447a18.7 18.7 0 01-5.818-3.935 18.596 18.596 0 01-3.933-5.815c-.962-2.218-1.443-4.585-1.443-7.1 0-2.516.482-4.883 1.444-7.102.963-2.219 2.274-4.159 3.934-5.82a18.668 18.668 0 015.818-3.942c2.219-.966 4.587-1.449 7.103-1.449 2.25 0 4.368.375 6.354 1.125 1.987.75 3.75 1.795 5.292 3.137l1.512-1.512c.332-.332.716-.498 1.151-.498.436 0 .82.168 1.152.504.332.336.498.722.498 1.158 0 .435-.166.819-.498 1.151l-1.5 1.5c1.208 1.349 2.235 2.982 3.081 4.898.846 1.916 1.269 4.198 1.269 6.848 0 2.517-.482 4.885-1.448 7.105-.965 2.22-2.278 4.158-3.939 5.816a18.698 18.698 0 01-5.822 3.932c-2.22.964-4.588 1.446-7.105 1.446zm.001-3.419c4.144 0 7.663-1.444 10.556-4.331 2.892-2.887 4.339-6.403 4.339-10.547 0-4.144-1.446-7.663-4.337-10.555-2.892-2.893-6.41-4.34-10.554-4.34s-7.662 1.446-10.555 4.338c-2.893 2.891-4.339 6.409-4.339 10.553 0 4.144 1.445 7.66 4.337 10.549 2.891 2.889 6.409 4.333 10.553 4.333z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_ddd_53_753"
          width="98"
          height="100"
          x="1491.7"
          y="187.141"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feMorphology
            in="SourceAlpha"
            radius="4"
            result="effect1_dropShadow_53_753"
          ></feMorphology>
          <feOffset dy="10"></feOffset>
          <feGaussianBlur stdDeviation="8"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.0431373 0 0 0 0 0.109804 0 0 0 0 0.27451 0 0 0 0.12 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_53_753"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="2.5"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.0235294 0 0 0 0 0.054902 0 0 0 0 0.137255 0 0 0 0.08 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_53_753"
            result="effect2_dropShadow_53_753"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feMorphology
            in="SourceAlpha"
            radius="2"
            result="effect3_dropShadow_53_753"
          ></feMorphology>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.156863 0 0 0 0 0.184314 0 0 0 0 0.266667 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="effect2_dropShadow_53_753"
            result="effect3_dropShadow_53_753"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect3_dropShadow_53_753"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_d_53_753"
          width="50"
          height="50"
          x="1514.7"
          y="204.141"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_53_753"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_53_753"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter2_di_53_753"
          width="45.259"
          height="45.159"
          x="1517.07"
          y="206.511"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_53_753"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_53_753"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="shape" result="effect2_innerShadow_53_753"></feBlend>
        </filter>
        <filter
          id="filter3_ddd_53_753"
          width="98"
          height="100"
          x="951"
          y="443.422"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feMorphology
            in="SourceAlpha"
            radius="4"
            result="effect1_dropShadow_53_753"
          ></feMorphology>
          <feOffset dy="10"></feOffset>
          <feGaussianBlur stdDeviation="8"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.0431373 0 0 0 0 0.109804 0 0 0 0 0.27451 0 0 0 0.12 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_53_753"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="2.5"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.0235294 0 0 0 0 0.054902 0 0 0 0 0.137255 0 0 0 0.08 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_53_753"
            result="effect2_dropShadow_53_753"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feMorphology
            in="SourceAlpha"
            radius="2"
            result="effect3_dropShadow_53_753"
          ></feMorphology>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.156863 0 0 0 0 0.184314 0 0 0 0 0.266667 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="effect2_dropShadow_53_753"
            result="effect3_dropShadow_53_753"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect3_dropShadow_53_753"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter4_di_53_753"
          width="30.223"
          height="39.1"
          x="983.886"
          y="465.372"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_53_753"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_53_753"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="shape" result="effect2_innerShadow_53_753"></feBlend>
        </filter>
        <filter
          id="filter5_ddd_53_753"
          width="98"
          height="100"
          x="409.291"
          y="186.136"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feMorphology
            in="SourceAlpha"
            radius="4"
            result="effect1_dropShadow_53_753"
          ></feMorphology>
          <feOffset dy="10"></feOffset>
          <feGaussianBlur stdDeviation="8"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.0431373 0 0 0 0 0.109804 0 0 0 0 0.27451 0 0 0 0.12 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_53_753"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="2.5"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.0235294 0 0 0 0 0.054902 0 0 0 0 0.137255 0 0 0 0.08 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_53_753"
            result="effect2_dropShadow_53_753"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feMorphology
            in="SourceAlpha"
            radius="2"
            result="effect3_dropShadow_53_753"
          ></feMorphology>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.156863 0 0 0 0 0.184314 0 0 0 0 0.266667 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="effect2_dropShadow_53_753"
            result="effect3_dropShadow_53_753"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect3_dropShadow_53_753"
            result="shape"
          ></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_53_753"
          x1="1000.52"
          x2="1000.52"
          y1="11.176"
          y2="270.11"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.352" stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_53_753"
          x1="1000"
          x2="1000"
          y1="549"
          y2="899.754"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#fff"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_53_753"
          x1="1540.7"
          x2="1540.7"
          y1="191.141"
          y2="265.141"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.5" stopColor="#F1F2FD"></stop>
          <stop offset="1" stopColor="#A4B2E6"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_53_753"
          x1="1540.7"
          x2="1540.7"
          y1="191.141"
          y2="265.141"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#ECEFFA"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_53_753"
          x1="1539.7"
          x2="1539.7"
          y1="206.511"
          y2="249.67"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6F76F4"></stop>
          <stop offset="1" stopColor="#3B2DC9"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear_53_753"
          x1="1000"
          x2="1000"
          y1="447.422"
          y2="521.422"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.5" stopColor="#F1F2FD"></stop>
          <stop offset="1" stopColor="#A4B2E6"></stop>
        </linearGradient>
        <linearGradient
          id="paint6_linear_53_753"
          x1="1000"
          x2="1000"
          y1="447.422"
          y2="521.422"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#ECEFFA"></stop>
        </linearGradient>
        <linearGradient
          id="paint7_linear_53_753"
          x1="998.997"
          x2="998.997"
          y1="465.372"
          y2="503.472"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#747DFC"></stop>
          <stop offset="1" stopColor="#3929CA"></stop>
        </linearGradient>
        <linearGradient
          id="paint8_linear_53_753"
          x1="458.291"
          x2="458.291"
          y1="190.136"
          y2="264.136"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.5" stopColor="#F1F2FD"></stop>
          <stop offset="1" stopColor="#A4B2E6"></stop>
        </linearGradient>
        <linearGradient
          id="paint9_linear_53_753"
          x1="458.291"
          x2="458.291"
          y1="190.136"
          y2="264.136"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#ECEFFA"></stop>
        </linearGradient>
        <linearGradient
          id="paint10_linear_53_753"
          x1="457.297"
          x2="457.297"
          y1="204.861"
          y2="247.636"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6F76F4"></stop>
          <stop offset="1" stopColor="#3E31CA"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default ImpactSVG;
