import React from "react";
import { motion } from "framer-motion";
import { Button } from "../button";

export default function FeatureSection() {
  return (
    <div className="w-full">
      <h1 className="font-title font-medium relative z-10 md:mt-48 text-slate-800 max-w-7xl text-3xl sm:text-6xl lg:text-[80px] sm:tracking-tight">
        Transform how you navigate environmental policy
      </h1>
      <div className="mt-8 md:mt-16 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 grid-rows-9 md:grid-rows-6 xl:grid-rows-4 gap-8 w-full">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          ease-out
          className="col-start-1 row-span-3 md:row-span-3 row-start-2 md:row-start-3 xl:row-start-1 border border-blue-100 border-opacity-50 rounded-3xl overflow-hidden flex-col items-start justify-start p-8 relative"
        >
          <img
            src="landing/grid1.webp"
            alt=""
            className="absolute inset-0 h-full w-full object-cover pointer-events-none"
          />
          <div className="p-8 absolute inset-0 flex flex-col w-full h-full items-start justify-start">
            <h3 className="font-title relative z-10 text-2xl font-medium text-slate-800">
              Automated legislative tracking
            </h3>
            <p className="mt-6 z-10 relative text-sm text-slate-600">
              By automating the process of tracking legislative activity,
              analyzing bill implications, and predicting lawmaker stances, our
              tool helps users save hundreds of hours compared to manual
              research methods.
            </p>
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          ease-out
          className="col-start-1 row-start-9 md:row-start-6 xl:row-start-4 border border-blue-100 border-opacity-50 rounded-3xl overflow-hidden flex-col items-start justify-start p-8 relative"
        >
          <img
            src="landing/grid2.webp"
            alt=""
            className="absolute inset-0 h-full w-full object-cover pointer-events-none"
          />
          <div className="p-8 absolute inset-0 flex flex-col w-full h-full items-start justify-end gap-4 lg:justify-between">
            <h3 className="font-title text-2xl font-medium text-slate-800">
              Supercharged Knowledge
            </h3>
            <p className="text-sm text-slate-600 max-w-64">
              Upload your own documents or industry reports, such as policy
              briefs or position papers.
            </p>
            <Button className="">Learn more</Button>
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          ease-out
          className="col-span-1 md:col-span-2 row-span-1 md:row-span-2 col-start-1 xl:col-start-2 row-start-1 border border-blue-100 border-opacity-50 rounded-3xl overflow-hidden relative"
        >
          <img
            src="landing/grid3a.webp"
            alt=""
            className="absolute inset-0 h-full w-full object-cover pointer-events-none"
          />
          <div className="absolute inset-0 flex flex-col w-full h-full items-center justify-center">
            <h3 className="font-title text-2xl font-medium max-w-lg md:text-2xl lg:text-5xl text-center text-medium text-slate-800">
              Predict how lawmakers will vote
            </h3>
            <p className="mtext-sm text-slate-600 max-w-96 md:max-w-lg mt-4 md:mt-4 text-center">
              Our advanced predictive analytics evaluate past behaviors and
              current trends to forecast voting outcomes, giving you a strategic
              advantage in your advocacy work.
            </p>
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          ease-out
          className="row-span-2 col-start-1 md:col-start-2 row-start-5 md:row-start-3 border border-blue-100 border-opacity-50 rounded-3xl overflow-hidden relative"
        >
          <img
            src="landing/grid4.webp"
            alt=""
            className="h-full w-full object-cover pointer-events-none"
          />
          <div className="p-8 absolute inset-0 flex flex-col w-full h-full items-start justify-end">
            <h3 className="font-title relative z-10 text-2xl font-medium text-slate-800">
              Identify environmental policy influencers
            </h3>
            <p className="mt-4 z-10 relative text-sm text-slate-600">
              Using our advanced predictive analytics you can identify which law
              makers influence policy in different environmental sectors by
              evaluating past behaviors and current trends.
            </p>
            <Button className="mt-4">Learn more</Button>
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          ease-out
          className="row-span-2 col-start-1 md:col-start-2 xl:col-start-3 row-start-7 md:row-start-5 xl:row-start-3 border border-blue-100 border-opacity-50 rounded-3xl overflow-hidden relative"
        >
          <img
            src="landing/grid5.webp"
            alt=""
            className="h-full w-full object-cover pointer-events-none"
          />
          <div className="p-8 absolute inset-0 flex flex-col w-full h-full items-start justify-end">
            <h3 className="font-title relative z-10 text-2xl font-medium text-slate-800">
              Real-time updates
            </h3>
            <p className="mt-4 z-10 relative text-sm text-slate-600">
              Customize alerts to ensure you’re kept informed on new bill
              introductions, amendments, and legislative actions that matter to
              you.
            </p>
            <Button className="mt-4">Learn more</Button>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
