import React from "react";
import { Button } from "./button";

export default function NavBar() {
  return (
    <div className="fixed left-0 top-0 w-screen z-20 h-16 bg-white bg-opacity-40 flex justify-center backdrop-blur-lg">
      <div className="container px-4 md:px-6 h-16 flex justify-between items-center">
        <img src="general/Pentatonic-logo.svg" alt="" className="w-32" />
        {/* <div className="flex gap-12 items-center text-sm cursor-pointer">
          <Button size="sm" variant="ghost" className="font-normal">
            Features
          </Button>
          <Button size="sm" variant="ghost" className="font-normal">
            Pricing
          </Button>
          <Button size="sm" variant="ghost" className="font-normal">
            About
          </Button>
        </div> */}
        <div className="flex gap-4 items-center text-sm cursor-pointer">
          <Button
            size="sm"
            variant="ghost"
            href="https://copilot-dev.policycopilot.org/"
            className="text-sm"
          >
            Log In
          </Button>
          <Button
            size="sm"
            href="https://copilot-dev.policycopilot.org/"
            className="text-sm"
          >
            Sign Up
          </Button>
        </div>
      </div>
    </div>
  );
}
