import React from "react";
import { motion } from "framer-motion";

export default function DataSection() {
  return (
    <div className="max-w-5xl m-auto mt-48">
      <h1 className="font-title font-medium text-slate-800 sm:tracking-tight text-3xl sm:text-6xl lg:text-[80px]">
        We've got policy covered
      </h1>
      <div>
        <p className="relative z-10 mt-8 text-slate-600 text-xl text-gray-500">
          Stay ahead of legislative changes effortlessly. Our AI tool tracks
          environmental policies in near real-time, consuming numerous data
          points daily to ensure you're always informed and ready to respond.
        </p>
      </div>
      <div className="grid grid-cols-3 gap-8 mt-12">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          ease-out
          className="relative"
        >
          <img
            src="landing/stats1.webp"
            alt=""
            className="w-full h-auto rounded-xl"
          />
          <div className="p-5 absolute inset-0 flex flex-col w-full h-full items-start justify-between">
            <h3 className="text-6xl font-medium text-slate-800 font-data">
              52
            </h3>
            <p className="text-lg text-slate-800 max-w-64 font-datacaption">
              Different Data Sources
            </p>
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          ease-out
          className="relative"
        >
          <img
            src="landing/stats2.webp"
            alt=""
            className="w-full h-auto rounded-xl"
          />
          <div className="p-5 absolute inset-0 flex flex-col w-full h-full items-start justify-between">
            <div>
              <h3 className="text-6xl font-medium text-slate-800 font-data">
                2,545
              </h3>
              <p className="text-lg text-slate-500 max-w-64 font-data uppercase mt-2 font-sm">
                +3 today
              </p>
            </div>
            <p className="text-lg text-slate-800 max-w-64 font-datacaption">
              Policy Documents
            </p>
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          ease-out
          className="relative"
        >
          <img
            src="landing/stats3.webp"
            alt=""
            className="w-full h-auto rounded-xl"
          />
          <div className="p-5 absolute inset-0 flex flex-col w-full h-full items-start justify-between">
            <h3 className="text-6xl font-medium text-slate-800 font-data">
              535
            </h3>
            <p className="text-lg text-slate-800 max-w-64 font-datacaption">
              Policy Makers Tracked
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
