import React from "react";
import { motion } from "framer-motion";
import { Button } from "../button";

export default function LegislationSection() {
  return (
    <div className="w-full mt-48 relative">
      <div className="absolute bottom-0 left-1/2 right-1/2 -ml-[50vw] -mr-[50vw] h-48 bg-gradient-to-b from-transparent to-blue-50 translate-y-20"></div>
      <h1 className="font-title font-medium relative z-10 m-auto text-center text-slate-800 max-w-4xl sm:tracking-tight text-3xl sm:text-6xl lg:text-[80px]">
        Curated by experts, powered by AI
      </h1>
      <p className="text-center relative z-10 mt-8 text-slate-600 max-w-prose mx-auto text-xl text-gray-500">
        The Legislation Tracker helps brands understand how rapidly-evolving
        environmental laws impact their operations
      </p>
      <motion.img
        src="landing/legislation.webp"
        alt=""
        className="w-full mt-12 md:mt-16 rounded-2xl drop-shadow-2xl border border-blue-100 border-opacity-50"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        ease-out
      />
      <div className="text-center">
        {" "}
        <p className="text-center relative z-10 mt-16 text-slate-600 max-w-3xl mx-auto text-sm text-gray-500">
          Providing updates on new legislation as it progresses through
          lawmaking bodies in the US. The Tracker can summarise laws, clarify
          vital obligations, define compliance requirements, and even build
          implementation plans.
        </p>
        <Button
          className="z-10 mt-10 relative"
          href="https://copilot-dev.policycopilot.org/"
        >
          Get Started
        </Button>
      </div>
    </div>
  );
}
